<template>

   <div id="gradient" class=" custom-login">
        <div class="center">
          <div><img width="250" src="/assets/img/logo_white.png" /></div>
          <div class="card">


            
            <div class="card-header card-header-primary" style="text-align: right !important;">
              <h4 class="card-title">تسجيل الدخول</h4>
              <p class="card-category">يرجى تعبئة بيانات الدخول</p>
            </div>
             <div class="card-body">
              <form  autocomplete='off'>
                <div class="row ">
                  <Input class_bs="col-md-12" id="email" title="البريد الإلكتروني" @Input="HandleInput('email',$event)" Mykey="email" :errors="service_error" :MyValue="form.email"/>
                  <Input class_bs="col-md-12" id="password" title="كلمة المرور" @Input="HandleInput('password',$event)" Mykey="password" :errors="service_error" :MyValue="form.password" MyType="password"/>
                </div>

                <button type="button" class="btn btn-primary pull-right" @click="login()" >دخول</button>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>

      </div>


</template>
<script>
import Input from '../../components/Form/TextInput.vue'
import {mapState} from 'vuex'
import store from '../../store/index'

export default {
    data(){
        return{
            form:{
                email:'',
                password:'',
            },
        }
    },
    created(){
   this.form.email="";
    },
    components:{
     Input
    },
    computed:{
    ...mapState({
       service_error:state=>state.admin.auth.errors,
    })
    },
    methods:{
      
      login(){
            store.commit('admin/PleaseStartLoading');
             this.$store.commit('admin/authCleanErrors');
            this.$store.dispatch('admin/authRequest', {
                email: this.form.email,
                password: this.form.password,
            })
            .then((response) => {
                store.commit('admin/PleaseStopLoading');
                this.$router.push({name: 'admin.dashboard.home'});
                                
             })
            .catch(() => {
              store.commit('admin/PleaseStopLoading');
            });
        },
     HandleInput(key,value){
         this.form[key]=value;
     },
     

    },
     beforeRouteEnter(to, from, next) {
       store.commit('admin/PleaseStopLoading');
       next();
    }, 
}
</script>

<style scoped>
.custom-login {
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.center{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.form__label{
  display: contents !important;
}
.card{
  width: 480px;
}
@media screen and (max-width: 550px){
 .card{
  width: 310px;
} 
} 




#gradient {
     background: linear-gradient(to right, #16918b,#1aa19a80); 
}



 
</style>

<style>
#app{
  margin-top: 0px !important;
}
</style>